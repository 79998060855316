import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT CAN I BUY WITH A CONVENTIONAL HOME LOAN?",
      answer: (
        <>
          <p className="mb-0">
            You can purchase a single-family home, condo, or townhome with a
            conventional mortgage through a private lender. The property can be
            your primary residence, a second home, or an investment property
            (such as a rental).
          </p>
        </>
      ),
    },
    {
      question: "HOW ARE INTEREST RATES DETERMINED?",
      answer: (
        <>
          <p className="mb-0">
            For lenders, it’s all about how much risk you pose to them as a
            borrower. When determining your interest rate, they’ll typically
            look at your credit score, the amount of debt you have, and how much
            of a down payment you plan to make. They’ll also take into
            consideration what the current market reflects.
          </p>
        </>
      ),
    },
    {
      question: "WHAT TYPE OF DOCUMENTATION WILL I NEED TO PROVIDE?",
      answer: (
        <>
          <p>
            For a conventional mortgage, lenders will typically want to see:
          </p>
          <ul className="list-disc pl-6">
            <li>A copy of your driver’s license</li>
            <li>
              Two years of W2s (or 2 years of full tax returns if you’re
              self-employed
            </li>
            <li>2 recent pay stubs</li>
            <li>
              A copy of your mortgage statement (if you’re currently paying on a
              home loan)
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Conventional Home Loans | Accel Mortgage | Pasadena Brokers"
        description="A conventional home loan from a private lender is a great option if you have a solid credit score and a low debt-to-income ratio. Learn more here!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Conventional Home Loan: When You Have Good Credit</h1>
              <p>
                A conventional mortgage from a private lender is a great option
                if you have a solid credit score and a low debt-to-income ratio.
                In many cases, you can put as little as 3% down on your new
                California home.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/2.0 Conventional Loans/1.0 Hero.png"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>The Advantages for Borrowers</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">More Options</h3>
                <p className="mb-0">
                  You can choose a term between 10 to 30 years and select either
                  a fixed or adjustable rate.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">Fast Funding</h3>
                <p className="mb-0">
                  Since conventional home loans aren’t backed by the government,
                  there’s less paperwork required and fewer delays.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">Low Down Payment</h3>
                <p className="mb-0">
                  You can put as little as 3% down on your new home, giving you
                  more opportunities to buy.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">No PMI Option</h3>
                <p className="mb-0">
                  With a 20% down payment, you’re not required to pay private
                  mortgage insurance (PMI).
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>Who’s Eligible?</h2>
              <p>
                With conventional home loans, lenders look for borrowers with
                financial stability and who have shown that they are responsible
                with their money. The qualifications include:
              </p>
              <ul className="styled-list-arrow">
                <li>Credit score of 620 or higher</li>
                <li>Debt-to-income ratio of less than 45%</li>
                <li>Minimum down payment of 3%</li>
                <li>Property appraisal</li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/2.0 Conventional Loans/Who’s Eligible.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
